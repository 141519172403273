import '../styles/fonts.css'
import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/nextjs'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'rc-pagination/assets/index.css'
import { purinaTheme, nestleTheme, ricoreTheme, theme } from '@miimosa/nestle/src/themes'

import Layout from '@components/Layout'
import Head from 'next/head'
import { s3ImageURL } from '@miimosa/common'

function FallbackComponent() {
  return <div>Une erreur est survenue</div>
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  let currentTheme = theme
  let description = 'NESTLÉ® soutien l’agriculture régénératrice en France'
  let title = 'Génération Régénération - NESTLÉ® soutient les agriculteurs'
  let image = 'og-nestle.png'

  // change current theme based on route found in AppProps
  if (router.route.includes('friskies')) {
    currentTheme = purinaTheme
    description =
      'FRISKIES® participe au financement de 3 projets d’agriculture régénératrice: votez pour votre projet agricole préféré.'
    title = 'Génération Régénération - FRISKIES® s’engage pour l’agriculture régénératrice'
    image = 'og-purina.png'
  } else if (router.route.includes('nestle')) {
    currentTheme = nestleTheme
    description = 'NESTLÉ® Céréales soutien de 2 agriculteurs français en agriculture biologique'
    title = 'Génération Régénération - NESTLÉ® Céréales soutient les agriculteurs'
    image = 'og-nestle.png'
  } else if (router.route.includes('ricore')) {
    currentTheme = ricoreTheme
    description =
      'RICORÉ® s’engage auprès des éleveurs laitiers fournisseurs de son usine à Challerange: votez pour votre projet agricole préféré.'
    title = 'Génération Régénération - RICORÉ® soutient les producteurs laitiers'
    image = 'og-ricore.png'
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} key="title" />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={s3ImageURL(image)} key="image" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <link rel="shortcut icon" href={s3ImageURL('favicon.ico')} />
        <link rel="icon" type="image/png" href={s3ImageURL('favicon.png')} sizes="64x64" />
      </Head>
      <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
        <ChakraProvider theme={currentTheme}>
          <Layout>{<Component {...pageProps} />}</Layout>
        </ChakraProvider>
      </Sentry.ErrorBoundary>
    </>
  )
}

export default MyApp
