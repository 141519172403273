import { defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react'
import localFont from 'next/font/local'
import { theme as baseTheme } from '@chakra-ui/theme'
import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const RicoreText = localFont({ src: '../../public/fonts/Kefa-Regular.ttf', display: 'swap' })

const baseStyle = definePartsStyle({
  label: {
    fontFamily: `${RicoreText.style.fontFamily}`,
    fontSize: '14px', // does not work
  },
})

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })

// black magic in here: it's a mix from chakra ui docs and ChatGPT solutions
// for some reason I was not able to make it simpler
const inputsProps = {
  bgColor: 'light_gray',
  p: '15px',
  m: '0',
  borderRadius: '0px',
  fontSize: '16px',
}

const inputFieldStyles = {
  variants: {
    outline: {
      field: inputsProps,
    },
  },
  defaultProps: {
    variant: 'outline',
  },
}
const outline = defineStyle(inputsProps)
export const textareaTheme = defineStyleConfig({
  variants: { outline },
})

export const ricoreTheme = extendTheme({
  colors: {
    brand: '#FFDC00',
    light_brand: '#FFF0D2',
    very_light_brand: '#FFF4E4',
    very_very_light_brand: '#FFFDF5', // yes, just trust the figma
    brand_text: '#66160E',
    active: '#8c1c14',
    light_brown: '#B3520B',
    light_gray: '#EDE9E4',
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      'html, body': {
        color: 'black',
        fontSize: '18px',
      },
    },
  },
  components: {
    Heading: {
      sizes: {
        md: {
          fontSize: { base: '21' },
          fontWeight: 700,
          lineHeight: 'normal',
        },
        lg: {
          fontSize: { base: '32', md: '36' },
          fontWeight: 700,
          lineHeight: 'normal',
        },
        xl: {
          fontSize: { base: '42' },
          fontWeight: 700,
          lineHeight: 'normal',
        },
      },
      variants: {},
      baseStyle: {
        color: 'brand_text',
      },
      // The default size and variant values
      defaultProps: {
        size: 'lg',
      },
    },
    Input: inputFieldStyles,
    Checkbox: checkboxTheme,
    Textarea: textareaTheme,
    Button: defineStyleConfig({
      // could not find how to override default prop
      variants: {
        brandPrimary: defineStyle({
          background: 'brand',
          color: 'brand_text',
          padding: '15px 30px',
          fontSize: '16',
          fontWeight: '700',
          borderRadius: '0px',
          w: 'fit-content',
        }),
      },
    }),
  },
  fonts: {
    heading: `${RicoreText.style.fontFamily}, ${baseTheme.fonts.heading}`,
    body: `${RicoreText.style.fontFamily}, ${baseTheme.fonts.body}`,
    NestleText: `${RicoreText.style.fontFamily}`,
  },
})
