import { theme as baseTheme, extendTheme, defineStyleConfig, defineStyle } from '@chakra-ui/react'
import localFont from 'next/font/local'

// Making sure chakra base sizes are not present
// eslint-disable-next-line @typescript-eslint/no-explicit-any
baseTheme.components.Heading.sizes = undefined as any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
baseTheme.components.Button.sizes = undefined as any

export const NestleText = localFont({
  src: [
    {
      path: '../../public/fonts/NestleText-Book.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/NestleText-Italic.ttf',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../../public/fonts/NestleText-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
  display: 'swap',
})

export const theme = extendTheme({
  components: {
    Heading: {
      sizes: {
        lg: {
          fontSize: { base: '28', md: '36' },
          fontWeight: 600,
          lineHeight: 'normal',
        },
        xl: {
          fontSize: { base: '50' },
          fontWeight: 600,
          lineHeight: 'normal',
        },
      },
      variants: {},
      baseStyle: {
        color: 'brand_dark_brown',
      },
      // The default size and variant values
      defaultProps: {
        size: 'lg',
      },
    },
    Button: defineStyleConfig({
      // could not find how to override default prop
      variants: {
        brandPrimary: defineStyle({
          background: 'brand_dark_brown',
          color: 'white',
          padding: '16px 32px',
          fontSize: '16',
          fontWeight: '500',
          borderRadius: '2px',
          w: 'fit-content',
        }),
        brandSecondary: defineStyle({
          padding: '12px 22px',
          fontSize: '16',
          w: 'fit-content',
          borderRadius: '2px',
          fontWeight: '500',
        }),
      },
    }),
  },
  styles: {
    global: {
      'a[href^="#"]': {
        scrollMarginBottom: '100px',
      },
      html: {
        scrollBehavior: 'smooth',
      },
      'html, body': {
        color: 'black',
      },
    },
  },
  shadows: {
    md: '0px 0px 16px rgba(0, 0, 0, 0.08)',
  },
  fonts: {
    heading: `${NestleText.style.fontFamily}, ${baseTheme.fonts.heading}`,
    body: `${NestleText.style.fontFamily}, ${baseTheme.fonts.body}`,
  },
  // Thes font size should not be used
  // Use the component's sizes instead
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
  },
  lineHeights: {
    md: '1,4rem',
  },
  colors: {
    brand_gray: '#F7F3F2',
    brand_light_brown: '#E7DFDA',
    brand_brown: '#A28668',
    brand_dark_brown: '#605140',
    brand_yellow: '#F9F1E2',
    nestle_green: '#64A573',
    ricore_yellow: '#FFDC00',
    purina_orange: '#EF702F',
    white: '#fff',
    error: '#F64C46',
    dark_green: '#003F2C',
  },
})
