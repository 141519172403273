import { defineStyle, defineStyleConfig, extendTheme, SystemStyleObject } from '@chakra-ui/react'
import { theme as baseTheme } from '@chakra-ui/theme'

const sizes: Record<string, SystemStyleObject> = {
  sm: {
    fontSize: { base: '1.5rem' },
    fontWeight: { base: 400 },
    lineHeight: { base: '1.875rem' },
  },
  md: {
    fontSize: { base: '1.5rem' },
    fontWeight: { base: 700 },
    lineHeight: { base: '1.875rem' },
  },
  lg: {
    fontSize: { base: '2rem' },
    fontWeight: { base: 700 },
    lineHeight: { base: '2.25rem' },
  },
  xl: {
    fontSize: { base: '2.5rem' },
    fontWeight: { base: 700 },
    lineHeight: { base: '2.875rem' },
  },
  '2xl': {
    fontSize: { base: '2rem' },
    fontWeight: { base: 900 },
    fontFamily: 'NexaRustSansBlack',
    lineHeight: { base: '2.875rem' },
  },
}

const Heading = {
  sizes,
  variants: {},
  // The default size and variant values
  defaultProps: {
    size: 'lg',
    color: 'black',
  },
}

const textSizes: Record<string, SystemStyleObject> = {
  '3xs': {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  '2xs': {
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '1rem',
  },
  xs: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  sm: {
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '1.25rem',
  },
  md: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  lg: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.5rem',
  },
  xl: {
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '1.75rem',
  },
  '2xl': {
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '1.75rem',
  },
}

export const Text = {
  textSizes,
  // Outdated, use the color attribute directly instead
  variants: {
    emphasis: {
      textTransform: 'uppercase',
      fontWeight: '500',
      fontSize: '0.625rem',
      lineHeight: '1rem',
    },
  },
  // The default size and variant values
  defaultProps: {
    fontWeight: '400',
    size: 'md',
    color: 'black',
  },
}

export const nestleTheme = extendTheme({
  components: {
    Container: {
      variants: {
        boxed: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxW: { base: '100%', md: 'min(90%, 900px)' },
        },
        full: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxW: '100%',
          maxWidth: '100%',
          padding: 0,
        },
      },
    },
    Heading,
    Text,
    Button: defineStyleConfig({
      variants: {
        tertiary: defineStyle({
          border: 0,
          outline: 0,
          width: 'fit-content',
          _focus: {
            outline: 0,
            boxShadow: 0,
          },
          bg: 'transparent',
          color: 'green',
          borderStyle: 'solid',
          borderWidth: '0',
          borderColor: 'transparent',
          _active: {
            bg: 'transparent',
            borderColor: 'transparent',
            color: 'active',
          },
          _hover: {
            bg: 'transparent',
            borderColor: 'transparent',
            color: 'active',
          },
          _focusVisible: {
            outlineOffset: '-6px',
            outlineColor: 'green',
            outlineWidth: '2px',
            outlineStyle: 'solid',
          },
          _disabled: {
            borderColor: 'transparent',
            color: 'disabled',
            opacity: 1,
          },
        }),
      },
    }),
  },
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      'html, body': {
        color: 'black',
      },
    },
  },
  shadows: {
    md: '0px 0px 16px rgba(0, 0, 0, 0.08)',
  },
  fonts: {
    heading: `NexaRustScript, ${baseTheme.fonts.heading}`,
    body: `Arial, ${baseTheme.fonts.body}`,
  },
  // Thes font size should not be used
  // Use the component's sizes instead
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
  },
  lineHeights: {
    md: '1,4rem',
  },
  colors: {
    brand: '#0D461D',
    active: '#0D461D',
    hover: '#0D461D',
    disabled: '#E0E0E0',
    green: '#1F7A38',
    light_green: '#9DC41C',
    success: '#00B077',
    green_soft: '#F5F9EC',
    wheat: '#FEF3E2',
    white: '#fff',
    error: '#F64C46',
  },
})
