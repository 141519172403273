import { defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react'
import { theme as baseTheme } from '@chakra-ui/theme'
import localFont from 'next/font/local'

const PurinaHeaders = localFont({ src: '../../public/fonts/AmaticSC-Regular.ttf', display: 'swap' })

// black magic in here: it's a mix from chakra ui docs and ChatGPT solutions
// for some reason I was not able to make it simpler
const inputsProps = {
  bgColor: '#FFFAF1',
  borderRadius: '0',
}
const inputFieldStyles = {
  variants: {
    outline: {
      field: inputsProps,
    },
  },
  defaultProps: {
    variant: 'outline',
  },
}
const outline = defineStyle(inputsProps)
export const textareaTheme = defineStyleConfig({
  variants: { outline },
})

export const purinaTheme = extendTheme({
  styles: {
    global: {
      html: {
        scrollBehavior: 'smooth',
      },
      'html, body': {
        color: 'black',
        lineHeight: 1.3,
      },
    },
  },
  colors: {
    brand: '#EF702F',
    brand_yellow: '#FFD462',
    brand_light_yellow: '#FFEEC7',
    brand_very_light_yellow: '#FFFAF1',
    brand_orange: '#EF702F',
    brand_red: '#ED1C24',
    brand_white: '#FFFAF1',
    brand_green: '#6C9900',
    brand_green_text: '#85BD00',
    brand_light_green: '#D1DE8B',
    brand_very_light_green: '#F5F7ED',
    brand_brown: '#E0B152',
    brand_dark_brown: '#765F21',
  },
  components: {
    Text: {
      baseStyle: {
        fontSize: '16px',
      },
    },
    Heading: {
      sizes: {
        lg: {
          fontSize: { base: '32' },
          fontWeight: 700,
          lineHeight: 'normal',
          fontFamily: `${PurinaHeaders.style.fontFamily}, ${baseTheme.fonts.heading}`,
        },
        xl: {
          fontSize: { base: 32, md: '50' },
          fontWeight: 700,
          lineHeight: 'normal',
        },
      },
      variants: {},
      baseStyle: {
        color: 'brand_green',
      },
      // The default size and variant values
      defaultProps: {
        size: 'xl',
      },
    },
    Input: inputFieldStyles,
    Textarea: textareaTheme,
    Image: {
      baseStyle: {
        borderRadius: '25px', // apparently this does not work
      },
    },
    Button: defineStyleConfig({
      // could not find how to override default prop
      variants: {
        brandPrimary: defineStyle({
          background: 'brand_orange',
          color: 'white',
          padding: '4',
          fontWeight: 'normal',
          fontSize: '16',
          borderRadius: '10px',
          w: 'fit-content',
          my: '6',
        }),
      },
      defaultProps: {
        variant: 'brandPrimary',
      },
    }),
  },
  fonts: {
    heading: `${PurinaHeaders.style.fontFamily}, ${baseTheme.fonts.heading}`,
    body: `Ambit, ${baseTheme.fonts.body}`,
  },
})
